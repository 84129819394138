@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Always apply these base styles */
.bg-image {
    background-size: cover;
    background-position: center;
}

/* Remove background image for screens smaller than a specific width, e.g., 768px */
@media (max-width: 640px) {
    .bg-image {
        background-image: none !important; /* Overrides inline style */
    }
}